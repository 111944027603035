import {
  BugOutlined,
  CustomerServiceOutlined,
  FileTextOutlined,
  FrownOutlined,
  MailOutlined,
  QuestionCircleOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { CommunityIcon } from '@marine/icons';
import { ReactNode } from 'react';

type HelpLink = {
  id: string;
  icon: ReactNode;
  title: string;
  text: string;
  url: {
    mdc: string;
    tba: string;
    tbd: string;
  };
};

export const helpLinks: HelpLink[] = [
  {
    id: 'support',
    icon: <CustomerServiceOutlined />,
    title: 'Help & Support Centre',
    text: 'Type in your question and get answers.',
    url: {
      mdc: 'https://support.marinedatacloud.com',
      tba: 'https://support.marinedatacloud.com',
      tbd: 'https://support.marinedatacloud.com',
    },
  },
  {
    id: 'faq',
    icon: <QuestionCircleOutlined />,
    title: 'FAQ',
    text: 'Answers to the most frequently asked questions.',
    url: {
      mdc: 'https://support.marinedatacloud.com/portal/en/kb/marine-data-cloud/frequently-asked-questions-faq',
      tba: 'https://support.marinedatacloud.com/portal/en/kb/theboatapp/frequently-asked-questions-faq',
      tbd: 'https://support.marinedatacloud.com/portal/en/kb/theboatdb/frequently-asked-questions-faq',
    },
  },
  {
    id: 'tutorials',
    icon: <FileTextOutlined />,
    title: 'Tutorials',
    text: 'Our features and how you can make the best of them.',
    url: {
      mdc: 'https://support.marinedatacloud.com/portal/en/kb/',
      tba: 'https://support.marinedatacloud.com/portal/en/kb/theboatapp/tutorials',
      tbd: 'https://support.marinedatacloud.com/portal/en/kb/theboatdb/tutorials',
    },
  },
  {
    id: 'community',
    icon: <CommunityIcon />,
    title: 'Community',
    text: 'Ask questions, exchange views and ideas.',
    url: {
      mdc: 'https://support.marinedatacloud.com/portal/en/community/marine-data-cloud',
      tba: 'https://support.marinedatacloud.com/portal/en/community/theboatapp',
      tbd: 'https://support.marinedatacloud.com/portal/en/community/theboatdb',
    },
  },
  {
    id: 'bug',
    icon: <BugOutlined />,
    title: 'Report a bug',
    text: 'Something’s not working? Please let us know!',
    url: {
      mdc: 'https://support.marinedatacloud.com/portal/en/newticket?departmentId=446184000000006907',
      tba: 'https://support.marinedatacloud.com/portal/en/newticket?departmentId=446184000000147791',
      tbd: 'https://support.marinedatacloud.com/portal/en/newticket?departmentId=446184000000145077',
    },
  },
  {
    id: 'wrongdata',
    icon: <FrownOutlined />,
    title: 'Report wrong data',
    text: 'Help us improve. We’d love to hear from you.',
    url: {
      mdc: 'https://support.marinedatacloud.com/portal/en/newticket?departmentId=446184000000006907',
      tba: 'https://support.marinedatacloud.com/portal/en/newticket?departmentId=446184000000147791',
      tbd: 'https://support.marinedatacloud.com/portal/en/newticket?departmentId=446184000000145077',
    },
  },
  {
    id: 'feature',
    icon: <StarOutlined />,
    title: 'Request a feature',
    text: 'Let’s build together the best experience for you and all sailors across the seven seas.',
    url: {
      mdc: 'https://support.marinedatacloud.com/portal/en/community/topics/new',
      tba: 'https://support.marinedatacloud.com/portal/en/community/topics/new',
      tbd: 'https://support.marinedatacloud.com/portal/en/community/topics/new',
    },
  },
  {
    id: 'contactus',
    icon: <MailOutlined />,
    title: 'Contact us',
    text: 'Drop us a line at Support@MarineDataCloud.com.',
    url: {
      mdc: 'mailto: support@marinedatacloud.com',
      tba: 'mailto: support@marinedatacloud.com',
      tbd: 'mailto: support@marinedatacloud.com',
    },
  },
];
