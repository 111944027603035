import styles from '@styles/Header/user-avatar.module.scss';
import { Avatar, Badge } from 'antd';
import { useMemo } from 'react';
import { useCurrentUser, useFilesUrlWithToken } from 'services/hooks';

const UserAvatar: React.FC = () => {
  const { data: userData } = useCurrentUser();

  const userInitials = useMemo(() => {
    const nameFirstChar = userData?.authUser?.name?.substring(0, 1).toLocaleUpperCase();
    const surnameFirstChar = userData?.authUser?.surname?.substring(0, 1).toLocaleUpperCase();

    if (nameFirstChar && surnameFirstChar) {
      return nameFirstChar + surnameFirstChar;
    }

    return '';
  }, [userData?.authUser?.name, userData?.authUser?.surname]);

  const { fileUrlWithToken } = useFilesUrlWithToken();

  const profilePictureUrl = useMemo(
    () => fileUrlWithToken(userData?.authUser?.image?.url),
    [userData?.authUser?.image?.url, fileUrlWithToken],
  );

  return (
    <div className={styles.container}>
      <Badge className="gold-badge" count={null} offset={[-2, 25]}>
        <Avatar src={profilePictureUrl} size={32} className={styles.container__avatar}>
          {userInitials}
        </Avatar>
      </Badge>
    </div>
  );
};

export default UserAvatar;
