import { SearchOutlined } from '@ant-design/icons';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { Space, Typography } from 'antd';
import { useConfig } from 'providers';
import { FC } from 'react';
import { Configure, Hits, Index, InstantSearch, Pagination, SearchBox } from 'react-instantsearch';

import BoatCard from './BoatCard';
import styles from './SearchPanel.module.scss';
import { SearchPanelProps } from './SearchPanel.props';
import { SectionTitle } from './SectionTitle';
const { Title } = Typography;

const SearchPanel: FC<SearchPanelProps> = ({ closeSearchModal }) => {
  const { meiliSearchApiKey, meiliSearchHostUrl } = useConfig();
  const searchClient = instantMeiliSearch(meiliSearchHostUrl, meiliSearchApiKey, {
    keepZeroFacets: true,
  });

  return (
    <div className={styles.searchPanelWrapper}>
      <Space className={styles.header} align="center">
        <SearchOutlined />
        <Title level={2} className={styles.headerTitle}>
          What are you looking for?
        </Title>
      </Space>
      <div className={styles.resultsWrapper}>
        <InstantSearch indexName="boat" searchClient={searchClient}>
          <SearchBox
            placeholder="Model, keywords, etc..."
            autoFocus
            submitIconComponent={() => <span className={styles.searchButton}>Search</span>}
          />
          <Index indexName="boat">
            <div>
              <SectionTitle title="boats" />
              <Hits hitComponent={(props) => <BoatCard closeSearchModal={closeSearchModal} {...props} />} key={'id'} />
              <Configure index="boat" key={'id'} />
              <Pagination />
            </div>
          </Index>
        </InstantSearch>
      </div>
    </div>
  );
};

export { SearchPanel };
