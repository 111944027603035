import { DownOutlined } from '@ant-design/icons';
import { Col, Divider, Dropdown, Grid, Row } from 'antd';
import Link from 'next/link';
import { FC, useMemo } from 'react';
import { useNavigation } from 'services/hooks/queries/useNavigation';
import styles from './BoatsMenu.module.scss';
import { BoatsMenuProps, TNavigationItems } from './BoatsMenu.props';
import { MobileBoatsMenu } from './MobileBoatsMenu';
import SectionItem from './SectionItem';

const BoatsMenu: FC<BoatsMenuProps> = (props) => {
  const { xl } = Grid.useBreakpoint();
  const { data } = useNavigation({ navigationIdOrSlug: '1' });

  const navigationGroups = useMemo<TNavigationItems>(() => {
    if (data !== null && data?.renderNavigation?.length > 0) {
      return data.renderNavigation[0].items;
    }

    return [];
  }, [data]);

  if (xl) {
    return (
      <Dropdown
        {...props}
        rootClassName={styles.rootClassName}
        dropdownRender={() => (
          <div className={styles.containerDropdown}>
            <Row gutter={12}>
              {navigationGroups.map((group) => (
                <Col sm={6} key={group.id}>
                  <SectionItem {...group} />
                </Col>
              ))}
            </Row>
            <Divider>Or</Divider>
            <div className={styles.footer}>
              <Link href="/boats" className={`ant-btn ant-btn-primary ant-btn-lg`}>
                Explore all boats
              </Link>
            </div>
          </div>
        )}
      >
        <span>
          Boats <DownOutlined />
        </span>
      </Dropdown>
    );
  }

  return <MobileBoatsMenu items={navigationGroups} />;
};

export { BoatsMenu };
