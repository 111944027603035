import { DownOutlined } from '@ant-design/icons';
import { Col, Dropdown, Grid, Row } from 'antd';
import { useConfig } from 'providers';
import { FC } from 'react';
import { useRecentNews } from 'services/hooks/queries/useRecentNews';
import { useRecentPosts } from 'services/hooks/queries/useRecentPosts';
import styles from './BlogMenu.module.scss';
import { BlogMenuProps } from './BlogMenu.props';
import { MobileBlogMenu } from './MobileBlogMenu';
import SectionItem from './SectionItem';

export const BlogsMenu: FC<BlogMenuProps> = (props) => {
  const { xl } = Grid.useBreakpoint();
  const { appCode } = useConfig();
  const { data: postsData, isLoading: isLoadingPosts } = useRecentPosts({ appCode, pageSize: 2 });
  const { data: newsData, isLoading: isLoadingNews } = useRecentNews({ appCode, limit: 2 });

  if (xl) {
    return (
      <Dropdown
        rootClassName={styles.rootClassName}
        {...props}
        dropdownRender={() => (
          <div className={styles.containerDropdown}>
            <Row gutter={12}>
              <Col sm={8}>
                <SectionItem isLoading={isLoadingPosts} items={postsData.posts.data} title="Blog" />
              </Col>
              <Col sm={8}>
                <SectionItem isLoading={isLoadingNews} items={newsData.newsItems.data} title="News" path="news" />
              </Col>
              <Col sm={8}>{/* <h3 className={styles.sectionTitle}>Personas</h3> */}</Col>
            </Row>
          </div>
        )}
      >
        <span>
          Blogs <DownOutlined />
        </span>
      </Dropdown>
    );
  }

  return (
    <>
      <MobileBlogMenu postsData={postsData} newsData={newsData} />
    </>
  );
};
