import {
  BulbOutlined,
  CustomerServiceOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  FundOutlined,
  MailOutlined,
  MessageOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { CommunityIcon, ExternalLinkIcon } from '@marine/icons';
import { MenuProps } from 'antd';
import Link from 'next/link';
import { useConfig } from 'providers';
import { useMemo } from 'react';
import { SuitcaseIcon } from '../../CustomIcons';

const TARGET_BLANK = { target: '_blank', rel: 'noopener' };

export const useMoreMenuItems = () => {
  const { mdcLandingUrl } = useConfig();

  const moreMenuItems: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'about',
        label: 'About TheBoatDB',
        type: 'group',
        children: [
          {
            key: 'how-it-works',
            icon: <BulbOutlined />,
            label: <Link href="/how-it-works">How it works</Link>,
          },
          {
            key: 'our-story',
            icon: <MessageOutlined />,
            label: <Link href="/our-story">Our story</Link>,
          },
          {
            key: 'careers',
            icon: <SolutionOutlined />,
            label: <Link href="/careers">Career</Link>,
          },
          {
            key: 'contact-us',
            icon: <MailOutlined />,
            label: (
              <Link href="mailto:Support@MarineDataCloud.com?subject=Support needed for TheBoatDB">Contact us</Link>
            ),
          },
        ],
      },
      {
        type: 'divider',
      },
      {
        key: 'pricing',
        label: 'Pricing',
        type: 'group',
        children: [
          {
            key: 'pricing-and-plans',
            icon: <BulbOutlined />,
            label: <Link href="/pricing">Pricing and Plans</Link>,
          },
        ],
      },
      {
        type: 'divider',
      },
      {
        key: 'support',
        label: 'Support',
        type: 'group',
        children: [
          {
            key: 'system-status',
            icon: <FundOutlined />,
            label: (
              <Link href="https://status.marinedatacloud.com/" {...TARGET_BLANK}>
                System status <ExternalLinkIcon />
              </Link>
            ),
          },
          {
            key: 'changelog',
            icon: <ProfileOutlined />,
            label: <Link href="/changelog">Changelog</Link>,
          },
          {
            key: 'faq',
            icon: <QuestionCircleOutlined />,
            label: (
              <Link
                href="https://support.marinedatacloud.com/portal/en/kb/theboatdb/frequently-asked-questions-faq"
                {...TARGET_BLANK}
              >
                FAQ <ExternalLinkIcon />
              </Link>
            ),
          },
          {
            key: 'tutorials',
            icon: <FileTextOutlined />,
            label: (
              <Link href="https://support.marinedatacloud.com/portal/en/kb/theboatdb" {...TARGET_BLANK}>
                Tutorials <ExternalLinkIcon />
              </Link>
            ),
          },
          {
            key: 'community',
            icon: <CommunityIcon />,
            label: (
              <Link href="https://support.marinedatacloud.com/portal/en/community/theboatdb" {...TARGET_BLANK}>
                Community <ExternalLinkIcon />
              </Link>
            ),
          },
          {
            key: 'support',
            icon: <CustomerServiceOutlined />,
            label: (
              <Link href="https://support.marinedatacloud.com" {...TARGET_BLANK}>
                Support <ExternalLinkIcon />
              </Link>
            ),
          },
        ],
      },
      {
        type: 'divider',
      },
      {
        key: 'legal',
        label: 'Legal',
        type: 'group',
        children: [
          {
            key: 'terms-of-service',
            icon: <SuitcaseIcon />,
            label: <Link href={`${mdcLandingUrl}/terms-of-service`}>Terms of service</Link>,
          },
          {
            key: 'user-agreement',
            icon: <ProfileOutlined />,
            label: <Link href={`${mdcLandingUrl}/user-agreement`}>User agreement</Link>,
          },
          {
            key: 'privacy-policy',
            icon: <FileProtectOutlined />,
            label: <Link href={`${mdcLandingUrl}/privacy-policy`}>Privacy policy</Link>,
          },
        ],
      },
    ],
    [mdcLandingUrl],
  );

  return moreMenuItems;
};
