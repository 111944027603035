/* eslint-disable @next/next/no-img-element */
import { MenuOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Grid } from 'antd';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { AnnouncementAlerts } from 'sailor-ui/AnnouncementAlerts';
import { SelectApp } from 'sailor-ui/SelectApp';
import styles from 'sailor-ui/styles/Header.module.scss';
import { useScrollHandler } from 'services';
import LeftMenu from './LeftMenu';
import { MainDrawer } from './MainDrawer';
import RightMenu from './RightMenu';
import UserDrawerNav from './UserNav/UserDrawerNav';

const PageHeader: React.FC = () => {
  const { isAuthenticated } = useAuth0();

  const { push } = useRouter();

  const { stickyHeader } = useScrollHandler();

  const [mainDrawer, setMainDrawer] = useState<boolean>(false);

  const { useBreakpoint } = Grid;
  const { xl } = useBreakpoint();

  const showDrawer = (): void => {
    setMainDrawer(true);
  };

  const onClose = useCallback((): void => {
    setMainDrawer(false);
  }, []);

  useEffect(() => {
    if (xl) {
      onClose();
    }
  }, [xl, onClose]);

  useEffect(() => {
    setMainDrawer(false);
  }, []);

  const handleOnHomeClick = useCallback(() => {
    push('/');
  }, [push]);

  return (
    <>
      <AnnouncementAlerts />
      <div>
        <header
          className={cn(styles.headerWrapper, {
            [styles.headerScrollDown]: stickyHeader,
            [styles.headerScrollUp]: !stickyHeader,
          })}
        >
          <nav className={styles.navWrapper}>
            <SelectApp currentApp="theboat_db" isResponsive={xl} onHomeClick={handleOnHomeClick} />

            <div className={styles.headerMenu}>
              {xl && <LeftMenu />}
              {xl && <RightMenu />}
            </div>

            {!xl && (
              <Button
                type="text"
                onClick={showDrawer}
                style={{ padding: '0 6px' }}
                icon={<MenuOutlined style={{ fontSize: 19 }} />}
              />
            )}
          </nav>
        </header>

        <MainDrawer goBack={false} onClose={onClose} visible={mainDrawer}>
          <LeftMenu />
          <RightMenu />
          {isAuthenticated && <UserDrawerNav />}
        </MainDrawer>
      </div>
    </>
  );
};

export default PageHeader;
