import { ONE_DAY_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useGetCurrencies } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useCurrencies() {
  const { graphQLClient } = useGraphQLClient();
  const { data: accessToken } = useAccessToken();

  return useGetCurrencies(
    graphQLClient,
    {},
    {
      cacheTime: ONE_DAY_IN_MILLISECONDS,
      enabled: !!accessToken,
    },
  );
}
