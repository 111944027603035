/* eslint-disable jsx-a11y/anchor-is-valid */
import { LogoutOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { ExternalLinkIcon } from '@marine/icons';
import { Avatar, Badge, Menu, MenuProps } from 'antd';
import { useConfig } from 'providers';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { useCurrentUser, useFilesUrlWithToken } from 'services';
import { PlanIcon } from '../PlanIcon';
import styles from './UserDropdown.module.scss';
import { UserDropdownProps } from './UserDropdown.props';

interface Props {
  menuItems?: UserDropdownProps['menuItems'];
  Link: UserDropdownProps['renderLink'];
  isNextJs: UserDropdownProps['isNextJs'];
  extra?: UserDropdownProps['extra'];
  hideMenu?: () => void;
}

const UserMenu: FC<Props> = ({ menuItems, Link, isNextJs, hideMenu, extra }) => {
  const { logout } = useAuth0();
  const { auth0LogoutReturnTo } = useConfig();

  const { data: userData } = useCurrentUser();

  const userInfo = useMemo(() => {
    const nameFirstChar = userData?.authUser?.name?.substring(0, 1).toLocaleUpperCase();
    const surnameFirstChar = userData?.authUser?.surname?.substring(0, 1).toLocaleUpperCase();

    if (nameFirstChar && surnameFirstChar) {
      const displayName = `${userData?.authUser?.name} ${userData?.authUser?.surname}`;

      return {
        initials: nameFirstChar + surnameFirstChar,
        displayName,
      };
    }

    return {
      initials: '',
      displayName: '',
    };
  }, [userData?.authUser?.name, userData?.authUser?.surname]);

  const { fileUrlWithToken } = useFilesUrlWithToken();

  const profilePictureUrl = useMemo(
    () => fileUrlWithToken(userData?.authUser?.image?.url),
    [userData?.authUser?.image?.url, fileUrlWithToken],
  );

  const handleLogout = useCallback(() => {
    logout({ logoutParams: { returnTo: auth0LogoutReturnTo || '/' } });
  }, [auth0LogoutReturnTo, logout]);

  const menuItemsList = useMemo((): MenuProps['items'] => {
    const logoutItem: MenuProps['items'] = [
      {
        key: 'logout',
        label: 'Logout',
        onClick: handleLogout,
        icon: <LogoutOutlined />,
        danger: true,
        className: styles.logoutItem,
      },
    ];

    const userMenuItems: MenuProps['items'] = menuItems?.map(({ title, items, key: groupKey }) => ({
      key: groupKey,
      label: title,
      type: 'group',
      className: styles.menuItemGroup,
      children: items?.map(({ key, url, icon, text, target, isRouter }) => {
        if (!!Link && !!isRouter) {
          if (isNextJs) {
            return {
              key,
              icon,
              label: (
                <Link href={url} target={target || '_self'} className={styles.userDropdownMenuItem}>
                  {text} {target === '_blank' && <ExternalLinkIcon />}
                </Link>
              ),
            };
          }

          return {
            key,
            icon,
            label: (
              <Link to={url} target={target || '_self'} className={styles.userDropdownMenuItem}>
                {text} {target === '_blank' && <ExternalLinkIcon />}
              </Link>
            ),
          };
        }

        return {
          key,
          label: (
            <a href={url} target={target || '_self'} className={styles.userDropdownMenuItem}>
              {text} {target === '_blank' && <ExternalLinkIcon />}
            </a>
          ),
          icon,
        };
      }),
    }));

    if (userMenuItems?.length) {
      return [...userMenuItems, ...logoutItem];
    }

    return [...logoutItem];
  }, [menuItems, Link, handleLogout, isNextJs]);

  const userBadge = useMemo<ReactNode>(() => {
    if (userData?.authUser?.current_subscription) {
      return (
        <span>
          <PlanIcon name={userData?.authUser?.current_subscription} styles={{ fontSize: 10 }} />
        </span>
      );
    }

    return null;
  }, [userData?.authUser?.current_subscription]);

  return (
    <div className={styles.userMenuContainer}>
      <div className={styles.userMenuHeader}>
        <Badge className="gold-badge" count={userBadge} offset={[-5, 32]}>
          <Avatar src={profilePictureUrl} size={40} className={styles.containerAvatar}>
            {userInfo.initials}
          </Avatar>
        </Badge>

        <span className={styles.menuHeaderName}>{userInfo.displayName}</span>
        <span className={styles.menuHeaderEmail}>{userData?.authUser?.email || ''}</span>

        {extra && <div style={{ marginTop: 8 }}>{extra}</div>}
      </div>

      <Menu className={styles.userDropdown} selectable={false} onClick={hideMenu} items={menuItemsList} />
    </div>
  );
};

export default UserMenu;
