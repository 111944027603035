import { BoatCardImage } from '@components/BoatCardImage';
import { Card } from 'antd';
import Link from 'next/link';
import React, { FC } from 'react';
import { Highlight } from 'react-instantsearch';
import styles from './SearchPanel.module.scss';
import { BoatCardProps } from './SearchPanel.props';

const { Meta } = Card;

const BoatCard: FC<BoatCardProps> = ({ hit, closeSearchModal }) => (
  <Link href={`/boat/${hit.slug}`}>
    <Card
      onClick={closeSearchModal}
      hoverable
      cover={
        <div className={styles.coverWrapper}>
          <BoatCardImage height={180} width={270} image={{ data: { attributes: hit.image } }} />
        </div>
      }
    >
      <Meta
        title={
          <div style={{ maxWidth: 318, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Highlight attribute="year_first" hit={hit} /> <Highlight attribute="manufacturer.name" hit={hit} />{' '}
            <Highlight attribute="model" hit={hit} />
          </div>
        }
        description={<Highlight attribute="model" hit={hit} />}
      />
    </Card>
  </Link>
);

export default BoatCard;
