import { DEFAULT_PER_PAGE } from 'mdc-constants';
import { SortOrder } from '../../graphql';

export const logsOfTripScreenVariables = {
  keyword: '',
  first: DEFAULT_PER_PAGE,
  orderBy: [
    {
      column: 'datetime',
      order: SortOrder.Desc,
    },
  ],
};
