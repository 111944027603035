import { Badge, BadgeProps } from 'antd';
import { ReactNode } from 'react';
import styles from './ripple-icon.module.scss';

export type RippleIconProps = {
  /**
   * Icon Component.
   */
  icon: ReactNode;
  /**
   * Takes BadgeProps.
   */
  badgeConfig?: BadgeProps;
  /**
   * Icon font size.
   */
  fontSize?: string | number;
};

export const RippleIcon = ({ icon, badgeConfig, fontSize = 20 }: RippleIconProps) => (
  <div className={styles.ripple}>
    <Badge className={styles.badge} {...badgeConfig}>
      <span style={{ fontSize }} className={styles.icon}>
        {icon}
      </span>
    </Badge>
  </div>
);
